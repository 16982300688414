class Rule {
  onlyNumber($event) {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      // 46 is dot
      $event.preventDefault();
    }
  }

  onlyInteger($event) {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if (keyCode < 48 || keyCode > 57) {
      // 46 is dot
      $event.preventDefault();
    }
  }

  formatAmount(value) {
    if (value == null) return null;

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  requiredField(v) {
    return [
      v => !!v || "This field is required",
      v => /^(?!\s*$).+/.test(v) || "This field cannot be blank"
    ]
  }

  requiredFile(v) {
    return [
      v => !!v || "File is required",
      v => (v && v.size > 0) || "File is required"
    ]
  }

  requiredObject(v) {
    return v && v.id !== null ? true : 'Object is required'
  }

  requiredRow(v) {
    return v == null || v != {} ? v : "You must select an option"

  }
}
export default new Rule();
