<template>
    <div>

        <v-card-title>
            <span class="primary--text">{{$t('caption.languageSkill')}} </span>
        </v-card-title>
        <v-card-text>
            <div v-for="(item, index) in skillItemList" :key="item.id">
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-select v-model="item.skillId" outlined :label="$t('select.selectLanguage')" item-value="id" :items="languageList" :rules="rule.requiredField()" hide-details dense>
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.skillNameKh}}
                                </span>
                                <span v-else>{{data.item.skillNameEn}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.skillNameKh}}
                                </span>
                                <span v-else>{{data.item.skillNameEn}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-select v-model="item.reading" outlined :label="$t('select.reading')" item-value="value" :items="gradeList" hide-details dense>
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-select v-model="item.writing" outlined :label="$t('select.writing')" item-value="value" :items="gradeList" hide-details dense>
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-select v-model="item.speaking" outlined :label="$t('select.speaking')" item-value="value" :items="gradeList" hide-details dense>
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-select v-model="item.listening" outlined :label="$t('select.listening')" item-value="value" :items="gradeList" hide-details dense>
                            <template slot="selection" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <span v-if="$t('lang') == 'KH'">
                                    {{data.item.text}}
                                </span>
                                <span v-else>{{data.item.text}}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="1">
                        <v-icon large color="primary" @click="removeItem(index)">{{icons.mdiDelete}}</v-icon>
                    </v-col>
                </v-row>
            </div>
            <v-row class="mt-5 justify-center">
                <v-col cols="4" align="center">
                    <v-btn class="btn primary" @click="addItem($event)" small rounded>
                        <v-icon small>{{icons.mdiPlus}}</v-icon> Language Skill</v-btn>
                </v-col>
            </v-row>
        </v-card-text>

    </div>
</template>

<script>
import { mdiCalendarAlert, mdiPlus, mdiMinus, mdiDelete } from '@mdi/js'
import SystemService from '@/services/service.system'
import StaffDetailService from '@/services/service.staffDetail'
import Rule from '@/plugins/rules.js'

export default {
  props: {
    data: Array
  },
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiMinus,
        mdiDelete
      },
      rule: [],
      skill: [],
      languageList: [],
      gradeList: [
        { value: 'A', text: 'ពូកែ' },
        { value: 'B', text: 'បង្គួរ' },
        { value: 'C', text: 'មធ្យម' },
        { value: 'D', text: 'ខ្សោយ' }
      ],
      skillItemList: []
    }
  },
  created() {
    this.rule = Rule

    SystemService.getLanguageSkills().then(response => {
      this.languageList = response.data
    })

    if (this.$route.params.action === 'edit') {
      this.staff = this.$route.params.data

      StaffDetailService.getLanguageSkillByStaffId(this.staff.id).then(
        response => {
          this.skillItemList = response.data
        }
      )
    }
  },
  watch: {
    skillItemList: function(val) {
      this.$emit('updateSkillList', val)
    }
  },
  methods: {
    addItem() {
      this.skillItemList.push({})
    },
    removeItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.skillItemList.splice(index, 1)
          }
        }
      })
    },

    uppercase(event) {
      //  alert(JSON.stringify(event.target.value));
      return event.target.value.toUpperCase()
      //this.code = this.code.toUpperCase();
    }
  }
}
</script>
