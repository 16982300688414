<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="primary--text-kh">{{$t('menu.caseFromPolice')}}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn class="ma-2" outlined @click="$router.push('/police-cases/add')">
              <v-icon left>{{icons.mdiPencil}}</v-icon>បញ្ចូលសំណុំរឿង
            </v-btn>

            <v-btn class="ma-2" outlined>Export</v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field :prepend-inner-icon="icons.mdiMagnify" label="Search" single-line outlined dense v-model="search"></v-text-field>
          </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="caseList">
          <template #[`item.no`]="{index, item}">
            <div class="text-left">
              {{index+1}}
            </div>

          </template>
          <template #[`item.caseNo`]="{item}">
            <div class="text-left">
              <v-chip color="primary" small rounded outlined @click="showDetail(item)">
                {{item.caseNo}}/{{item.caseRegisDate}}
              </v-chip>
            </div>
          </template>
          <template #[`item.actions`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs}">
                <v-btn v-bind="attrs" v-on="on" text fab dark color="primary" @click="transferCase(item)" :disabled="item.isActive">
                  <v-icon>{{icons.mdiTransferRight}}</v-icon>
                </v-btn>
              </template>
              <span>ចុះសំណុំរឿងនៅតុលាការ</span>
            </v-tooltip>

          </template>
          <template #[`item.isActive`]="{item}">
            <div class="d-flex flex-column">
              <span v-if="item.isActive">
                <v-chip class="success" outlined>បានបញ្ជូន</v-chip>
              </span>
              <span v-else>
                <v-chip class="warning primary--text">មិនទាន់បញ្ជូន</v-chip>
              </span>
            </div>
          </template>
          <template #[`item.isActualOffense`]="{item}">
            <div class="d-flex flex-column">
              <span v-if="item.isActualOffense">
                ជាក់ស្តែង
              </span>
              <span v-else>
                មិនជាក់ស្តែង
              </span>
            </div>
          </template>
          <template #[`item.user`]="{item}">
            <div class="d-flex flex-column">
              {{item.user.username}}
            </div>
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen hide-overlay>
          <v-card class="absolute" height="100%">
            <v-toolbar>
              <v-btn text fab dark large color="primary" @click="dialog=false">
                <v-icon>{{icons.mdiClose}}</v-icon>
              </v-btn>
              <v-toolbar-title>
                <span class="primary--text-kh">{{$t('case.policeCaseInfo')}} </span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" @click="editCase(selectedCaseId)">
                <v-icon left>{{icons.mdiReceiptTextEdit}}</v-icon> កែប្រែ
              </v-btn>
            </v-toolbar>
            <div class="scrollable-content">
              <v-card-text class="mt-5">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table class="borderless-table elevation-1">
                      <tr>
                        <th>លេខសំណុំរឿង</th>
                        <th>កាលបរិច្ឆេទទទួលសំណុំរឿង</th>
                        <th>ថ្ងៃចុះក្នុងប្រព័ន្ធ</th>
                        <th>ប្រភេទករណី</th>

                      </tr>
                      <tr style="text-align:center">
                        <td>{{policeCase.caseNo}}</td>
                        <td>{{policeCase.caseRegisDate}}</td>
                        <td>{{policeCase.modidate}}</td>
                        <td>
                          <span v-if="policeCase.isActualOffense">ជាក់ស្តែង</span>
                          <span v-else>មិនជាក់ស្តែង</span>
                        </td>

                      </tr>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="8">
                    <h3 class="text-h5">ព័ត៌មានជនទាក់ទិន</h3>
                    <v-simple-table class="borderless-table elevation-1">
                      <tr>
                        <th>ល.រ</th>
                        <th>ឈ្មោះ</th>
                        <th>ភេទ</th>
                        <th>អាយុ</th>
                        <th>ភាគី</th>
                        <th>វត្តមាន</th>
                      </tr>
                      <tr style="text-align:center" v-for="(item, index) in partyList" :key="item.id">
                        <td>{{index+1}}</td>
                        <td>{{item.nameKh}}</td>
                        <td>
                          <span v-if="item.gender">ស្រី</span>
                          <span v-else>ស្រី</span>
                        </td>
                        <td>{{item.age}}</td>
                        <td v-if="item.partyRole">{{item.partyRole.roleNameKh}}</td>
                        <td>
                          <span v-if="item.showup">
                            មានខ្លួន
                          </span>
                          <span v-else>
                            អត់ខ្លួន
                          </span>
                        </td>
                      </tr>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" md="4" class="grow">
                    <v-row>
                      <v-col cols="12">
                        <v-alert text color="info" style="border: 2px dotted black">
                          <h3 class="text-h5">ការចោទប្រកាន់នៃបទល្មើស</h3>
                          <v-divider class="my-3 info" style="opacity: 0.22"></v-divider>
                          <p v-for="(item, index) in criminalCharges" :key="item.id">
                            {{index+1}} .{{item.caseCrimeType.crimeTypeNameKh}}
                          </p>
                        </v-alert>
                      </v-col>
                      <v-col cols="12">
                        <v-alert text color="info" style="border: 2px dotted black">
                          <h3 class="text-h5">ពិពណ៌នាអំពីភស្តុតាង</h3>
                          <v-divider class="my-3 info" style="opacity: 0.22"></v-divider>
                          <p v-if="policeCase.evidenceDescription">
                            {{policeCase.evidenceDescription}}
                          </p>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiCalendarAlert,
  mdiPencil,
  mdiClose,
  mdiTransfer,
  mdiTransferRight,
  mdiReceiptTextEdit
} from '@mdi/js'
import PoliceCaseService from '@/services/service.policeCase'
import Rule from '@/plugins/rules.js'

export default {
  name: 'police-cases',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPencil,
        mdiClose,
        mdiTransfer,
        mdiTransferRight,
        mdiReceiptTextEdit
      },
      rule: '',
      search: '',
      policeCase: {},
      caseList: [],
      partyRoleList: [],
      partyList: [],
      criminalCharges: [],
      dialog: false,
      selectedCaseId: '',
      headers: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខរបាយការណ៍', value: 'caseNo' },
        { text: 'ថ្ងៃទទួលពាក្យបណ្តឹង', value: 'caseRegisDate' },
        { text: 'ករណី', value: 'isActualOffense' },
        { text: 'បានបញ្ជូនទៅតុលាការ', value: 'isActive' },
        { text: 'អ្នកបញ្ចូល ', value: 'user' },
        { text: 'ACTION', value: 'actions', sortable: false }
      ]
    }
  },
  async created() {
    this.rule = Rule

    await PoliceCaseService.getCaseAll().then(response => {
      this.caseList = response.data
    })

    await PoliceCaseService.getCasePartyRoleAll().then(response => {
      this.partyRoleList = response.data
    })
  },
  methods: {
    async showDetail(item) {
      this.policeCase = item
      this.selectedCaseId = item.id

      await PoliceCaseService.getPartyByCaseId(this.selectedCaseId).then(
        response => {
          this.partyList = response.data
        }
      )

      await PoliceCaseService.getChargeByCaseId(this.selectedCaseId).then(
        response => {
          this.criminalCharges = response.data
        }
      )

      this.dialog = true
    },
    editCase(id) {
      // alert(id);
      this.$router.push({
        name: 'police-cases-edit',
        params: { action: 'edit', id: id }
      })
    },
    transferCase(item) {
      //   alert(JSON.stringify(item))

      this.$router.push({
        name: 'police-cases-transfer',
        params: { action: 'transfer', id: item.id }
      })
    }
  }
}
</script>
<style>
.scrollable-content {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}
</style>
